import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e4e6188"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-deviations-message ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_icon, { icon: _ctx.checkmarkCircleOutline }, null, 8, ["icon"]),
    (_ctx.deviationType === 'open')
      ? (_openBlock(), _createBlock(_component_ion_label, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode("There are no open deviations in the selected areas.")
          ]),
          _: 1
        }))
      : (_ctx.deviationType === 'closed')
        ? (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode("There are no closed deviations in the selected areas.")
            ]),
            _: 1
          }))
        : (_ctx.deviationType === 'hoc-rated')
          ? (_openBlock(), _createBlock(_component_ion_label, { key: 2 }, {
              default: _withCtx(() => [
                _createTextVNode("No HoC ratings have been given or changed in the last 30 calendar days for the selected areas.")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
  ]))
}