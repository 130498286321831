import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-597c6f94"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"text-align":"right","margin-top":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviationProperty = _resolveComponent("DeviationProperty")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, { size: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviationProperty, {
              header: "Case ID",
              value: _ctx.deviation.id
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, { size: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviationProperty, {
              header: _ctx.dateHeader,
              value: _ctx.dateFormatted
            }, null, 8, ["header", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, { size: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviationProperty, {
              header: "Phase",
              value: _ctx.phaseText
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, { size: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviationProperty, {
              header: "CVP",
              value: _ctx.deviation.organization.cvp
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, { size: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviationProperty, {
              header: "Dept.",
              value: _ctx.deviation.organization.department
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_col, { size: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviationProperty, {
              header: "Team",
              value: _ctx.deviation.organization.team
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_row, null, {
      default: _withCtx(() => [
        (_ctx.deviationType === 'open')
          ? (_openBlock(), _createBlock(_component_ion_col, {
              key: 0,
              size: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DeviationProperty, {
                  header: "Lead time",
                  value: _ctx.leadTime.daysSinceCreatedText,
                  valueMarkingColor: _ctx.leadTime.showWarningMarking ? '#F2CD66' : 'initial'
                }, null, 8, ["value", "valueMarkingColor"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.deviationType === 'hoc-rated')
          ? (_openBlock(), _createBlock(_component_ion_col, {
              key: 1,
              size: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DeviationProperty, {
                  header: "HoC Rating",
                  value: _ctx.hocRatingText
                }, null, 8, ["value"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_col, { size: "4" }, {
          default: _withCtx(() => [
            _createVNode(_component_DeviationProperty, {
              header: "Batch related",
              value: _ctx.batchRelatedText
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.novoglowLinkVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ion_button, {
            class: "novoglow-button",
            size: "small",
            shape: "round",
            fill: "outline",
            href: _ctx.novoglowLink,
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" NovoGlow "),
              _createVNode(_component_ion_icon, {
                icon: _ctx.openOutline,
                slot: "end"
              }, null, 8, ["icon"])
            ]),
            _: 1
          }, 8, ["href"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}