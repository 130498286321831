import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-492e9570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nofilter-message ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_icon, {
      class: "column-item",
      icon: _ctx.businessOutline
    }, null, 8, ["icon"]),
    _createVNode(_component_ion_label, { class: "column-item" }, {
      default: _withCtx(() => [
        _createTextVNode("Please select at least one organizational area where you want to see deviations from.")
      ]),
      _: 1
    }),
    _createVNode(_component_ion_button, {
      class: "column-item",
      "router-link": "/settings/filter",
      "router-direction": "forward"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Select Area")
      ]),
      _: 1
    })
  ]))
}